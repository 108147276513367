body {
  --color-job-background: rgba(255, 241, 232, 0.38);
  --color-job-foreground: --color-foreground;
  --color-job-shadow: rgb(255, 203, 213);
  --color-job-title-shadow: #ffd9d0;

  --color-detail-bullet-foreground: #5eb2be;
  --character-detail-bullet: '\2766';
}
:global(body.dark) {
  --color-job-background: #d3055b;
  --color-job-foreground: white;
  --color-job-shadow: black;
  --color-job-title-shadow: rgba(145, 48, 131, 0.49);

  --color-detail-bullet-foreground: #ff8888;
  --character-detail-bullet: '\263D';
}

.Job {
  max-width: 800px;
  margin: 20px auto 20px;
  padding: 20px 20px 40px 20px;
  background: var(--color-job-background);
  border-radius: 3px;
  color: var(--color-job-foreground);
  box-shadow: 2px 2px 3px 0px var(--color-job-shadow);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  &:last-of-type {
    border-bottom: 0;
  }

  h3 {
    margin-bottom: 0;
    text-shadow: 2px 2px 0px var(--color-job-title-shadow);
  }
  h5 {
    margin-top: 0;
  }
}

.detail {
  list-style: square;
  margin: 20px 0;
  padding: 0;
  text-align: left;

  li {
    margin: 10px 0;
    position: relative;
    text-indent: 1.3em;
    &:before {
      position: absolute;
      content: var(--character-detail-bullet);
      top: 0;
      left: -1.3em;
      color: var(--color-detail-bullet-foreground);
    }
    list-style-type: none;
  }
}

@media print {

  .Job {
    max-width: 80%;
  }

  .tags {
    li {
      font-weight: bold;
      box-shadow: none;
    }
  }
}
